import api from '../services/api';

export const isTokenExpired = (token: string): boolean => {
  if (!token) {
    return false;
  }

  const jwt = JSON.parse(atob(token.split('.')[1]));

  // multiply by 1000 to convert seconds into milliseconds
  const tokenExpirationDate = (jwt && jwt.exp && jwt.exp * 1000) || undefined;

  if (!tokenExpirationDate) {
    return false;
  }

  return Date.now() > tokenExpirationDate;
};

export const requestNewAccessToken = async (): Promise<any> => {
  const refreshToken =
    localStorage.getItem('@Vispunt:refreshToken') || undefined;

  // Requesting new Access Token
  const { data: newAccessToken } = await api.post('sessions/token', {
    refreshToken,
  });

  // Storing new AccessToken
  localStorage.setItem('@Vispunt:token', newAccessToken);

  return newAccessToken.token;
};
