import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, ButtonLink } from './styles';

import ModalAddProduct from '../ModalAddProduct';
import ModalAddCategory from '../ModalAddCategory';

import { useAuth } from '../../hooks/auth';

const AdminMenu: React.FC = () => {
  const { user } = useAuth();
  const [openModalAddProduct, setOpenModalAddProduct] = useState(false);
  const [openModalAddCategory, setOpenModalAddCategory] = useState(false);

  useEffect(() => {
    async function loadModal(): Promise<void> {
      setOpenModalAddProduct(openModalAddProduct);
    }

    loadModal();
  }, [setOpenModalAddProduct, openModalAddProduct]);

  return (
    <>
      {user.admin && (
        <Container>
          <nav>
            <ul>
              <li>Admin</li>
              <li>
                <Link to="/orders">
                  <strong>Orders</strong>
                </Link>
              </li>
              <li>
                <Link to="/users">
                  <strong>Users</strong>
                </Link>
              </li>
              <li>
                <ButtonLink
                  type="button"
                  onClick={() => setOpenModalAddProduct(true)}
                >
                  <div className="text">Add Product</div>
                </ButtonLink>
              </li>
              <li>
                <ButtonLink
                  type="button"
                  onClick={() => setOpenModalAddCategory(true)}
                >
                  <div className="text">Add Category</div>
                </ButtonLink>
              </li>
            </ul>
          </nav>
          <ModalAddProduct
            isOpen={openModalAddProduct}
            setIsOpen={() => setOpenModalAddProduct(false)}
          />
          <ModalAddCategory
            isOpen={openModalAddCategory}
            setIsOpen={() => setOpenModalAddCategory(false)}
          />
        </Container>
      )}
    </>
  );
};

export default AdminMenu;
