import React, { createContext, useCallback, useState, useContext } from 'react';

interface IProductsState {
  categoryId: string;
}

interface ICategory {
  id: string;
  name: string;
  description: string;
}

interface IProductsContextData {
  categoryId: string;
  updateProducts(categoryId: string): void;
}

export const ProductsContext = createContext<IProductsContextData>(
  {} as IProductsContextData,
);

export const ProductsProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<IProductsState>(() => {
    const categoryId = '';

    if (categoryId) {
      return { categoryId };
    }

    return {} as IProductsState;
  });

  const updateProducts = useCallback(
    (categoryId) => {
      setData({
        categoryId,
      });
    },
    [setData],
  );

  return (
    <ProductsContext.Provider
      value={{ categoryId: data.categoryId, updateProducts }}
    >
      {children}
    </ProductsContext.Provider>
  );
};

export function useProducts(): IProductsContextData {
  const context = useContext(ProductsContext);

  if (!context) {
    throw new Error('useProducts must be user within an ProductsProvider');
  }
  return context;
}
