import React, {
  useMemo,
  useCallback,
  useState,
  ChangeEvent,
  createRef,
} from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

import { FiShoppingCart } from 'react-icons/fi';

import {
  ShoppingCartContainer,
  ShoppingCartCard,
  TableContainer,
  ButtonPay,
  LabelContainer,
  Label,
  TextArea,
  InputContainer,
  IconContainer,
  ValueToPay,
} from './styles';

import api from '../../services/api';
import { useToast } from '../../hooks/toast';

import formatValue from '../../utils/formatValue';

import { useCart } from '../../hooks/cart';
import { useAuth } from '../../hooks/auth';

interface IProduct {
  id: string;
  name: string;
  description: string;
  price: number;
  price_label: string;
  image: string;
  hashtags: string;
  available: boolean;
  category_id: string;
}

interface IOrder {
  id: string;
  total: number;
  products: IProduct[];
  deliveryDate: Date;
  deliveryInstructions: string;
}

const CheckoutCard: React.FC = () => {
  const { user } = useAuth();
  const { products } = useCart();
  const [availableDeliveryDates] = useState([1, 3, 5, 6]);
  const [deliveryDate, setDeliveryDate] = useState();
  const [deliveryInstructions] = useState(user.delivery_instructions);
  const deliveryDateRef = createRef<HTMLInputElement>();
  const deliveryInstructionsRef = createRef<HTMLTextAreaElement>();

  const { addToast } = useToast();
  // const history = useHistory();

  const cartTotal = useMemo(() => {
    if (products.length < 1) {
      return 0;
    }

    const totalPrice = products.reduce(
      (total, product) => total + Number(product.price) * product.quantity,
      0,
    );

    return totalPrice;
  }, [products]);

  const totalItensInCart = useMemo(() => {
    if (products.length < 1) {
      return 0;
    }

    const totalItens = products.reduce(
      (total, product) => total + product.quantity,
      0,
    );

    return totalItens;
  }, [products]);

  // Return next date of the week day provided
  const nextWeekdayDateString = useCallback((day_in_week: number): string => {
    const ret = new Date(new Date());
    ret.setDate(ret.getDate() + ((day_in_week - 1 - ret.getDay() + 7) % 7) + 1);

    return ret.toISOString();
  }, []);

  // Return next date of the week day provided
  const nextWeekdayDate = useCallback((day_in_week: number) => {
    const ret = new Date(new Date() || new Date());
    ret.setDate(ret.getDate() + ((day_in_week - 1 - ret.getDay() + 7) % 7) + 1);

    const weekDay = format(ret, 'EEEE');
    const dayMonth = format(ret, 'dd/MMMM');

    return (
      <>
        {weekDay} <small>{dayMonth}</small>
      </>
    );
  }, []);

  const handleSetDeliveryDate = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setDeliveryDate(event.target.value);
    },
    [setDeliveryDate],
  );

  const handleOrder = useCallback(async () => {
    if (deliveryDate === undefined) {
      addToast({
        type: 'info',
        title: 'Missing information',
        description: 'You need to select delivery date before Pay.',
      });
      return;
    }

    try {
      // Create Order
      const order = {
        total: cartTotal,
        products,
        delivery_date: deliveryDate,
        delivery_instructions: deliveryInstructionsRef.current?.value || '',
      };

      const orderCreated = await api.post<IOrder>('/orders', order);
      addToast({
        type: 'success',
        title: 'OrderCreated!',
        description:
          'Order Created! Transfering you to Mollie, our payment service!',
      });

      // Create Payment
      await api
        .post('/payments', {
          order_id: orderCreated.data.id,
          amount: orderCreated.data.total,
        })
        .then((response) => {
          window.location = response.data.url;
        });
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Error processing your order, please try again later.',
        description: `Error processing your order. ${err.message}`,
      });
    }
  }, [products, addToast, cartTotal, deliveryDate, deliveryInstructionsRef]);

  return (
    <ShoppingCartContainer>
      <ShoppingCartCard>
        <TableContainer>
          <p>
            <span>
              <IconContainer>
                <FiShoppingCart />
              </IconContainer>{' '}
              Shopping Cart Checkout
            </span>
            <Link to="/">
              <small>Continue Shopping</small>
            </Link>
          </p>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Price</th>
                <th>Quantity</th>
              </tr>
            </thead>

            <tbody>
              {products.map((cartProduct) => (
                <tr key={cartProduct.id}>
                  <td className="name">{cartProduct.name}</td>
                  <td>
                    {formatValue(cartProduct.price)} {cartProduct.price_label}
                  </td>
                  <td>{cartProduct.quantity}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableContainer>
        <section>
          <p>Please select the delivery day</p>
          <LabelContainer>
            {availableDeliveryDates.map((weekDayNumber) => (
              <Label key={weekDayNumber}>
                <input
                  type="radio"
                  ref={deliveryDateRef}
                  name="deliveryDate"
                  value={nextWeekdayDateString(weekDayNumber)}
                  onChange={handleSetDeliveryDate}
                />
                {nextWeekdayDate(weekDayNumber)}
              </Label>
            ))}
          </LabelContainer>
          <InputContainer>
            <label>
              Delivery Instructions / Cleaning Instructions / Special Request
            </label>
            <TextArea
              className="textarea"
              ref={deliveryInstructionsRef}
              placeholder="Please don't ring the bel."
              defaultValue={deliveryInstructions}
            />
          </InputContainer>
        </section>
        Total <ValueToPay>{totalItensInCart}</ValueToPay> items - Total{' '}
        <ValueToPay>{formatValue(Number(cartTotal))}</ValueToPay>&nbsp;
        <ButtonPay className="checkout" onClick={handleOrder}>
          Pay
        </ButtonPay>
      </ShoppingCartCard>
    </ShoppingCartContainer>
  );
};

export default CheckoutCard;
