import styled from 'styled-components';

export const Container = styled.section`
  padding: 1em 2em;
  color: #646464;
  margin: 0 auto;

  ul {
    list-style-type: none;
    display: flex;

    li {
      margin-left: 1em;
      font-weight: bold;
      color: #646464;

      a {
        text-decoration: none;
        transition: color 0.2s;
        color: #646464;

        &:hover {
          color: #36bcef;
        }
      }
    }
  }
`;

export const ButtonLink = styled.button`
  margin-left: 0 !important;
  transition: color 0.2s;
  font-weight: 500;
  color: #646464;

  &:hover {
    color: #36bcef;
  }
`;
