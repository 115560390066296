import React from 'react';

import 'react-day-picker/lib/style.css';
import { Container } from './styles';

import Header from '../../components/Header';
import OrdersList from '../../components/OrdersList';

const Orders: React.FC = () => {
  return (
    <Container>
      <Header isModalOpen={false} />

      <OrdersList />
    </Container>
  );
};

export default Orders;
