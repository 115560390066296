import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  color: #7c7c7c;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: 2px solid #d5d5d5;
  text-align: left;

  ul {
    list-style: none;
    line-height: 1.2rem;
  }
`;
