import styled, { css } from 'styled-components';

interface IFoodPlateProps {
  available: boolean;
}

interface IProductDescriptionProps {
  active: boolean;
}

export const ButtonExpand = styled.span`
  font-size: 16px;
  background: #37bdef;
  color: #ffffff;
  padding: 0px 6px;
  border-radius: 4px;
  cursor: pointer;
  position: absolute;
  bottom: -10px;
  left: 50%;
`;

export const Container = styled.div<IFoodPlateProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  background: #f0f0f5;
  border-radius: 8px;
  max-width: 370px;
  margin-bottom: 4em;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.02);

  @media (max-width: 768px) {
    margin: 2em;
  }

  @media (max-width: 400px) {
    width: 270px;
  }

  @media only screen and (min-width: 768px) {
    opacity: 0.9;
    transition: 0.4s opacity;

    :hover {
      opacity: 1;
    }
  }

  header {
    background: #ffb84d;
    border-radius: 8px 8px 0px 0px;
    height: 170px;
    overflow: hidden;
    transition: 0.3s opacity;
    text-align: center;

    ${(props) =>
      !props.available &&
      css`
        opacity: 0.3;
      `};

    img {
      pointer-events: none;
      user-select: none;
      width: 100%;
      min-height: 176px;
    }
  }

  section.body {
    padding: 15px;
    display: flex;
    flex-direction: column;

    p {
      margin-top: 5px;
    }
  }

  section.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;

    padding: 20px 30px;
    background: #e4e4eb;
    border-radius: 0px 0px 8px 8px;

    div.icon-container {
      display: flex;
    }

    div.availability-container {
      display: flex;
      align-items: center;

      p {
        color: #3d3d4d;
      }

      .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 20px;
        margin-left: 6px;

        & input {
          opacity: 0;
          width: 0;
          height: 0;
        }

        .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #c72828;
          -webkit-transition: 0.4s;
          transition: 0.4s;
          border-radius: 4px;

          &:before {
            position: absolute;
            content: '';
            height: 14px;
            width: 20px;
            left: 4px;
            bottom: 3px;
            background-color: white;
            -webkit-transition: 0.4s;
            transition: 0.4s;
            border-radius: 4px;
          }
        }

        input:checked + .slider {
          background-color: #30abd9;
        }

        input:focus + .slider {
          box-shadow: 0 0 1px #2196f3;
        }

        input:checked + .slider:before {
          -webkit-transform: translateX(32px);
          -ms-transform: translateX(32px);
          transform: translateX(32px);
        }
      }
    }
  }
`;

export const ProductName = styled.h2`
  color: #3d6db6;
  font-size: 20px;
`;

export const ProductDescription = styled.p<IProductDescriptionProps>`
  font-family: 'Lucida Grande', 'Lucida Sans Unicode', sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: 0.4s hidden;
  width: 290px;

  @media (max-width: 400px) {
    width: initial;
  }

  ${(props) =>
    props.active &&
    css`
      white-space: normal;
      overflow: initial;
      text-overflow: initial;
    `};
`;

export const ProductHashtags = styled.p`
  font-size: 12px;
  font-family: 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Geneva,
    sans-serif;

  span {
    display: inline-block;
    padding: 0px 4px;
    border-radius: 2px;
    font-weight: 500;
    color: white;
    background: #3d6db6;
    margin-right: 4px;
    margin-bottom: 4px;
  }
`;

export const ProductCategory = styled.p`
  font-size: 12px;
  background: aqua;
  padding: 0 2px;
  text-transform: uppercase;
  border-radius: 2px;
  position: absolute;
  top: 5px;
  left: -12px;
  font-family: 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Geneva,
    sans-serif;
`;

export const TitleContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SemiFooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ProductPrice = styled.p`
  position: absolute;
  font-size: 25px;
  top: 140px;
  right: -15px;
  background: #37bdef;
  border-radius: 5px;
  padding: 2px 11px;
  color: #ffe607;
  box-shadow: -3px 1px 15px #43778b;
  cursor: pointer;

  small {
    font-size: 12px;
  }

  span {
    position: absolute;
    right: 1px;
    top: -9px;
  }
`;
