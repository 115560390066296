import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
import { FiCheckSquare, FiSquare } from 'react-icons/fi';
import { useProducts } from '../../hooks/products';
import api from '../../services/api';
import { Container, Item, Menu } from './styles';

interface ICategory {
  id: string;
  name: string;
  description: string;
}

const SearchProducts: React.FC = () => {
  const { categoryId, updateProducts } = useProducts();
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [categories, setCategories] = useState<ICategory[]>([]);

  const handleClick = (e: any, catId: string): void => {
    if (!e.target.classList.value.includes('inactive')) {
      setSelectedCategories([]);
      updateProducts(catId === categoryId ? '' : catId);
    } else {
      setSelectedCategories([catId]);
      updateProducts(catId === categoryId ? '' : catId);
    }
  };

  useEffect(() => {
    async function loadCategories(): Promise<void> {
      const { data } = await api.get('/categories');
      setCategories(data);
    }

    loadCategories();
  }, []);

  return (
    <Container>
      <nav>
        <Menu>
          {categories &&
            categories.map((category) => (
              <Item
                className={
                  selectedCategories.includes(category.id)
                    ? 'active'
                    : 'inactive'
                }
                key={category.id}
                onClick={(e) => handleClick(e, category.id)}
              >
                {selectedCategories.includes(category.id) ? (
                  <FiCheckSquare className="active" size={20} />
                ) : (
                  <FiSquare className="inactive" size={20} />
                )}
                {category.name}
              </Item>
            ))}

          {/* <Item onClick={handleRemoveFilters}>All Products</Item> */}
        </Menu>
      </nav>
    </Container>
  );
};

export default SearchProducts;
