import React from 'react';
import { Link } from 'react-router-dom';
import {
  FiAward,
  FiUser,
  FiLogOut,
  FiShoppingCart,
  FiLock,
} from 'react-icons/fi';

import { Container, HeaderContent, Profile } from './styles';
import logoImg from '../../assets/logo.svg';
import { useAuth } from '../../hooks/auth';
import AdminMenu from '../AdminMenu';
import { useCart } from '../../hooks/cart';

interface IHeaderProps {
  isModalOpen: boolean;
}

const Header: React.FC<IHeaderProps> = ({ isModalOpen }) => {
  const { signOut, user } = useAuth();
  const { clean: CartClean } = useCart();

  async function handleLogout(): Promise<void> {
    signOut();
    CartClean();
  }

  return (
    <Container>
      <header>
        <HeaderContent>
          <Link to="/">
            <img src={logoImg} alt="Vispunt" />
          </Link>
          {user && <AdminMenu />}
          <Profile>
            {user ? (
              <>
                <div className="nowrap">
                  <Link to="/profile">
                    {user.admin ? <FiAward size={15} /> : <FiUser size={15} />}{' '}
                    <strong>{user.name}</strong>{' '}
                  </Link>
                </div>
                {!user.admin && (
                  <div className="nowrap">
                    <Link to="/orders">
                      <FiShoppingCart size={15} /> <strong>My Orders</strong>
                    </Link>
                  </div>
                )}

                <button type="button" onClick={handleLogout} className="nowrap">
                  <FiLogOut size={15} />{' '}
                  <strong className="nomobile">Logout</strong>
                </button>
              </>
            ) : (
              <>
                <div>
                  <Link to="/signup">
                    <FiUser size={15} /> <strong>Register</strong>
                  </Link>
                </div>
                <div>
                  <Link to="/login">
                    <FiLock size={15} /> <strong>Login</strong>
                  </Link>
                </div>
              </>
            )}
          </Profile>
        </HeaderContent>
      </header>
    </Container>
  );
};

export default Header;
