import styled, { css } from 'styled-components';
import Tooltip from '../Tooltip';

interface IContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  hasError: boolean;
}

export const Container = styled.div<IContainerProps>`
  background: #d5e6ff;
  border-radius: 4px;
  padding: 7px;
  width: 100%;

  border: 2px solid #d5e6ff;
  color: #a2c8ff;

  transition: color, border-color 0.4s;

  display: flex;
  align-items: center;

  & + div {
    margin-top: 8px;
  }

  ${(props) =>
    props.hasError &&
    css`
      border-color: #ff0000;
    `}

  ${(props) =>
    props.isFocused &&
    css`
      color: #36bdef;
      border-color: #36bdef;
    `}

  ${(props) =>
    props.isFilled &&
    css`
      color: #36bdef;
    `}

  input {
    flex: 1;
    background: transparent;
    border: 0;
    color: #3d6db6;

    &::placeholder {
      color: #a2c8ff;
    }

    @media only screen and (max-width: 768px) {
      font-size: 11px;
    }
  }

  svg {
    margin-right: 16px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;

  svg {
    margin: 0;
  }

  span {
    background: #ff0000;
    color: #fff;

    &::before {
      border-color: #ff0000 transparent;
    }
  }
`;
