import styled from 'styled-components';
import { Form as Unform } from '@unform/web';

import { shade } from 'polished';

export const Form = styled(Unform)`
  padding: 10px 20px;
  display: flex;
  flex-direction: column;

  h1 {
    font-weight: 600;
    font-size: 26px;
    line-height: 26px;
    margin-bottom: 30px;
  }

  button {
    align-self: flex-end;
  }

  button {
    font-weight: 600;
    border-radius: 8px;
    border: 0;
    background: #30abd9;
    color: #fff;

    display: flex;
    flex-direction: row;
    align-items: center;

    .text {
      padding: 16px 24px;
    }

    .icon {
      display: flex;
      padding: 16px 16px;
      background: #36bdef;
      border-radius: 0 8px 8px 0;
      margin: 0 auto;
    }
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ImageInput = styled.div`
  margin-bottom: 32px;
  position: relative;

  align-self: center;
  img {
    width: 40%;
  }

  span {
    margin-right: 60px;
  }

  label {
    position: absolute;
    width: 48px;
    height: 48px;
    background: #3d6db6;
    border-radius: 50%;
    right: 0;
    bottom: 0;
    border: 0;
    cursor: pointer;
    transition: background-color 0.2s;

    display: flex;
    align-items: center;
    justify-content: center;

    input {
      display: none;
    }

    svg {
      width: 20px;
      height: 20px;
      color: #312e38;
    }

    &:hover {
      background: ${shade(0.2, '#3d6db6')};
    }
  }
`;
