import React, { useCallback, useRef, useState } from 'react';
import {
  FiMail,
  FiUser,
  FiLock,
  FiArrowLeft,
  FiHome,
  FiPhone,
  FiAward,
} from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory, Link } from 'react-router-dom';
import { debounce } from 'lodash';
import api from '../../services/api';
import searchAddress from '../../services/searchAddress';

import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErrors';

import Input from '../../components/Input';
import { Button } from '../../components/Button';

import { Container, Content } from './styles';
import { useAuth } from '../../hooks/auth';
import DeliveryInstructions from '../../components/DeliveryInstructions';

interface IProfileFormData {
  name: string;
  email: string;
  old_password: string;
  password: string;
  password_confirmation: string;
  address: string;
  address_number: string;
  address_complement: string;
  delivery_instructions: string;
  address_postcode: string;
  address_city: string;
  address_state: string;
  address_country: string;
  mobile_phone: string;
  sharing_code: string;
}

const Profile: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();
  const { user, updateUser } = useAuth();
  const [validAddress, setValidAddress] = useState(true);

  const handleSearchAddress = debounce(async () => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        housenumber: Yup.number().required('House number is required.'),
        postalcode: Yup.string()
          .matches(/^\d{4}\s?\w{2}$/, 'Invalid PostCode')
          .required('PostCode is required.'),
      });

      const addressPostCode = formRef.current?.getFieldValue(
        'address_postcode',
      );
      const addressNumber = formRef.current?.getFieldValue('address_number');

      const addressSearch = {
        postalcode: addressPostCode,
        housenumber: addressNumber,
      };

      await schema.validate(addressSearch, {
        abortEarly: false,
      });

      const addressResponse = await searchAddress(addressSearch);

      setValidAddress(true);

      formRef.current?.setFieldValue('address', addressResponse.streetName);
      formRef.current?.setFieldValue('address_city', addressResponse.city);
    } catch (err) {
      setValidAddress(false);
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);

        return;
      }

      addToast({
        type: 'error',
        title: 'Error on your registration process.',
        description: 'We cannot find your address.',
      });
    }
  }, 500);

  const handleSubmit = useCallback(
    async (data: IProfileFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Name Required'),
          email: Yup.string()
            .required('E-mail Required')
            .email('Digit Valid Email'),

          old_password: Yup.string(),

          password: Yup.string().when('old_password', {
            is: (val) => !!val.length,
            then: Yup.string()
              .required('Field Required')
              .min(6, 'Minimun of 6 digits'),
            otherwise: Yup.string(),
          }),

          password_confirmation: Yup.string()
            .when('old_password', {
              is: (val) => !!val.length,
              then: Yup.string().required('Field Required'),
              otherwise: Yup.string(),
            })
            .oneOf([Yup.ref('password'), null], 'Passwords must match'),
          address: Yup.string().required(
            'Address Required, please enter your street name',
          ),
          address_number: Yup.string().required(
            'Number Required, please enter your house number',
          ),
          address_complement: Yup.string(),
          delivery_instructions: Yup.string(),
          address_postcode: Yup.string().required(
            'PostCode Required, please enter your PostCode. Ex 2432 SW',
          ),
          address_city: Yup.string().required(
            'City Required, please enter the city where you live',
          ),
          mobile_phone: Yup.string().required(
            'Mobile Phone Required, please enter your Mobile Phone',
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const {
          name,
          email,
          old_password,
          password,
          password_confirmation,
          address,
          address_number,
          address_complement,
          delivery_instructions,
          address_postcode,
          address_city,
          address_state,
          address_country,
          mobile_phone,
        } = data;

        const formData = {
          name,
          email,
          ...(old_password
            ? {
                old_password,
                password,
                password_confirmation,
              }
            : {}),
          address,
          address_number,
          address_complement,
          delivery_instructions,
          address_postcode,
          address_city,
          address_state,
          address_country,
          mobile_phone,
        };

        const response = await api.put('/profile', formData);

        updateUser(response.data);

        history.push('/');

        addToast({
          type: 'success',
          title: 'Profile Updated!',
          description: 'Your informations has been updated successfully.',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        // Add Toast
        addToast({
          type: 'error',
          title: 'Error on updating the profile',
          description: 'An Error Occur during updating the profile, try again.',
        });
      }
    },
    [addToast, history, updateUser],
  );

  return (
    <Container>
      <header>
        <div>
          <Link to="/">
            <FiArrowLeft />
          </Link>
        </div>
      </header>
      <Content>
        <Form
          ref={formRef}
          initialData={{
            name: user.name,
            email: user.email,
            address: user.address,
            address_number: user.address_number,
            delivery_instructions: user.delivery_instructions,
            address_postcode: user.address_postcode,
            address_city: user.address_city,
            mobile_phone: user.mobile_phone,
            sharing_code: user.sharing_code,
            admin: user.admin,
          }}
          onSubmit={handleSubmit}
        >
          <div>
            <h1>
              <span>
                {user.admin ? <FiAward size={24} /> : <FiUser size={24} />}
              </span>
              My Profile
            </h1>
          </div>
          <Input name="name" icon={FiUser} placeholder="Name" />
          <Input name="email" icon={FiMail} placeholder="E-mail" />
          <Input name="mobile_phone" icon={FiPhone} placeholder="0640554466" />
          <Input
            name="delivery_instructions"
            placeholder="Delivery Instructions"
            containerStyle={{ marginBottom: 24 }}
          />
          {/* <Input
            name="sharing_code"
            icon={FiShare}
            placeholder="YourSharingCode"
            disabled
          /> */}

          <p style={{ textAlign: 'left', marginBottom: 5 }}>Delivery Address</p>
          <Input
            name="address_postcode"
            placeholder="2333 SX"
            style={{ textTransform: 'uppercase' }}
            onChange={handleSearchAddress}
          />
          <Input
            name="address_number"
            placeholder="Number"
            onChange={handleSearchAddress}
          />
          <Input name="address" icon={FiHome} placeholder="Address" readOnly />
          <Input
            name="address_city"
            placeholder="Den Haag"
            readOnly
            containerStyle={{ marginBottom: 24 }}
          />

          <p style={{ textAlign: 'left', marginBottom: 5 }}>Change Password</p>
          <Input
            name="old_password"
            icon={FiLock}
            type="password"
            placeholder="Current password"
          />

          <Input
            name="password"
            icon={FiLock}
            type="password"
            placeholder="Password"
          />

          <Input
            name="password_confirmation"
            icon={FiLock}
            type="password"
            placeholder="Password confirmation"
          />

          <DeliveryInstructions />

          <Button type="submit" disabled={!validAddress}>
            Confirm Changes
          </Button>
        </Form>
      </Content>
    </Container>
  );
};

export default Profile;
