import api from './api';

interface ISearchAddressProps {
  housenumber: string;
  postalcode: string;
}

interface IResponse {
  streetName: string;
  city: string;
}

const searchAddress = async (
  postCodeAndNumber: ISearchAddressProps,
): Promise<IResponse> => {
  const res = await api.post('/users/postcode', postCodeAndNumber);
  const { streetName, city } = res.data[0];

  return {
    streetName,
    city,
  };
};

export default searchAddress;
