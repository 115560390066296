import React, { useCallback, useRef, useState } from 'react';
import {
  FiArrowLeft,
  FiMail,
  FiUser,
  FiLock,
  FiHome,
  FiPhone,
} from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import { debounce } from 'lodash';
import api from '../../services/api';
import searchAddress from '../../services/searchAddress';

import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErrors';

import logoImg from '../../assets/logo.svg';

import Input from '../../components/Input';
import { Button } from '../../components/Button';

import {
  Container,
  Content,
  Background,
  AnimationContainer,
  Logo,
} from './styles';
import DeliveryInstructions from '../../components/DeliveryInstructions';

interface ISignUpFormData {
  name: string;
  email: string;
  password: string;
  address: string;
  address_number: string;
  address_postcode: string;
  address_city: string;
  address_state: string;
  address_country: string;
  delivery_instructions: string;
  mobile_phone: string;
}

const SignUp: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();
  const [validAddress, setValidAddress] = useState(false);

  const handleSearchAddress = debounce(async () => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        housenumber: Yup.number().required('House number is required.'),
        postalcode: Yup.string()
          .matches(/^\d{4}\s?\w{2}$/, 'Invalid PostCode')
          .required('PostCode is required.'),
      });

      const addressPostCode = formRef.current?.getFieldValue(
        'address_postcode',
      );
      const addressNumber = formRef.current?.getFieldValue('address_number');

      const addressSearch = {
        postalcode: addressPostCode,
        housenumber: addressNumber,
      };

      await schema.validate(addressSearch, {
        abortEarly: false,
      });

      const addressResponse = await searchAddress(addressSearch);

      setValidAddress(true);

      formRef.current?.setFieldValue('address', addressResponse.streetName);
      formRef.current?.setFieldValue('address_city', addressResponse.city);
    } catch (err) {
      setValidAddress(false);
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);

        return;
      }

      addToast({
        type: 'error',
        title: 'Error on your registration process.',
        description: 'We cannot find your address.',
      });
    }
  }, 500);

  const handleSubmit = useCallback(
    async (data: ISignUpFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Name is required.'),
          email: Yup.string().required().email('Email is required.'),
          password: Yup.string().min(
            6,
            'Password should have at least 6 characters.',
          ),
          address_number: Yup.number().required('House number is required.'),
          address_postcode: Yup.string()
            .matches(/^\d{4}\s?\w{2}$/, 'Invalid PostCode')
            .required('PostCode is required.'),
          mobile_phone: Yup.string().required('Mobile phone is required.'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/users', data);
        history.push('/');

        addToast({
          type: 'success',
          title: 'Account created!',
          description: 'Now you can login at Vispunt.nl!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Error on your registration process.',
          description:
            'Error ocurred on your registration process. Try again later.',
        });
      }
    },
    [addToast, history],
  );

  return (
    <Container>
      <Background />
      <Content>
        <AnimationContainer>
          <Logo src={logoImg} alt="Vispunt" />
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Input
              name="name"
              icon={FiUser}
              placeholder="Name"
              autoComplete="disabled"
              autoFocus
            />
            <Input
              name="email"
              icon={FiMail}
              placeholder="E-mail"
              autoComplete="disabled"
            />
            <Input
              name="password"
              icon={FiLock}
              type="password"
              placeholder="Password"
              autoComplete="disabled"
            />
            <Input
              name="mobile_phone"
              icon={FiPhone}
              placeholder="Mobile Phone: 0640554466"
            />
            <Input
              name="address_postcode"
              placeholder="PostCode: 2333SX"
              style={{ textTransform: 'uppercase' }}
              onChange={handleSearchAddress}
            />

            <Input
              name="address_number"
              placeholder="House Number: 51"
              onChange={handleSearchAddress}
            />
            <Input
              name="address"
              icon={FiHome}
              placeholder="Street Name"
              readOnly
              containerStyle={{ marginTop: 24 }}
            />
            <Input name="address_city" placeholder="City" readOnly />

            <Button type="submit" disabled={!validAddress}>
              Register
            </Button>
          </Form>

          <DeliveryInstructions />

          <Link to="/login">
            <FiArrowLeft />
            Back to Sign in
          </Link>
          <Link to="/">
            <FiArrowLeft />
            Back to Home
          </Link>
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default SignUp;
