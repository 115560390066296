import React, { useRef, useCallback } from 'react';
import { FiLogIn, FiMail, FiLock, FiArrowLeft } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';

import logoImg from '../../assets/logo.svg';

import Input from '../../components/Input';
import { Button } from '../../components/Button';

import {
  Container,
  Content,
  Background,
  AnimationContainer,
  Logo,
} from './styles';

interface ISignInFormData {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { signIn } = useAuth();
  const { addToast } = useToast();

  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: ISignInFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string().required().email("Email's missing."),
          password: Yup.string().required("Password's missing."),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const response = await signIn({
          email: data.email,
          password: data.password,
        });

        if (response?.status === 'error') {
          addToast({
            type: 'error',
            title: `Error Login`,
            description: `Error occured trying to login. ${response.message}`,
          });

          history.push('/login');
          return;
        }

        history.push('/');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Error trying to authenticate.',
          description: `Error occured trying to login.`,
        });
      }
    },
    [signIn, addToast, history],
  );

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <Logo src={logoImg} alt="Vispunt" />

          <Form ref={formRef} onSubmit={handleSubmit}>
            <Input name="email" icon={FiMail} placeholder="E-mail" />
            <Input
              name="password"
              icon={FiLock}
              type="password"
              placeholder="Password"
            />
            <Button type="submit">Sign in</Button>
            <Link to="/forgot-password">Forgot my password.</Link>
          </Form>

          <Link to="/signup">
            <FiLogIn />
            Create your account
          </Link>

          <Link to="/">
            <FiArrowLeft />
            Back to Home
          </Link>
        </AnimationContainer>
      </Content>
      <Background />
    </Container>
  );
};

export default SignIn;
