import React, { useState, useEffect, useCallback } from 'react';

// import { useRouteMatch } from 'react-router-dom';
import 'react-day-picker/lib/style.css';
import { format, parseISO } from 'date-fns';
import { Main, Content, TableContainer } from './styles';

import Header from '../../components/Header';

import api from '../../services/api';

interface IUser {
  id: string;
  name: string;
  email: string;
  address: string;
  address_number: string;
  address_complement: string;
  address_postcode: string;
  address_city: string;
  address_state: string;
  address_country: string;
  mobile_phone: string;
  delivery_instructions: string;
  sharing_code: string;
  admin: boolean;
  created_at: Date;
}

const AdminOrders: React.FC = () => {
  const [users, setUsers] = useState<IUser[]>([]);

  const loadUsers = useCallback(async () => {
    const { data } = await api.get('/users');
    setUsers(data);
  }, []);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  // const handleMarkUserDisable = useCallback(
  //   async (id) => {
  //     try {
  //       const response = await api.put('/orders-management', {
  //         id,
  //         user_id: user.id,
  //       });

  //       const updatedState = users.filter((item) => item.id !== id);

  //       setUsers([...updatedState, response.data]);

  //       addToast({
  //         type: 'success',
  //         title: 'Delivered!',
  //         description: 'Order marked Delivered!',
  //       });
  //     } catch (err) {
  //       addToast({
  //         type: 'error',
  //         title: 'Error processing your order, please try again later.',
  //         description: `Error processing your order trying to mark delivered. ${err.message}`,
  //       });
  //     }
  //   },
  //   [loadUsers, addToast],
  // );

  return (
    <div>
      <Header isModalOpen={false} />

      <Main>
        <Content>
          <TableContainer>
            <header>
              <h2>Registered Users</h2>
            </header>
            <table>
              <thead>
                <tr>
                  <th>name</th>
                  <th>phone</th>
                  <th>created at</th>
                  {/* <th>Orders</th> */}
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {users?.map((singleUser) => (
                  <tr key={singleUser.id}>
                    <td className="small">{singleUser.name}</td>
                    <td className="small">{singleUser.mobile_phone}</td>
                    <td className="small">
                      {format(
                        parseISO(singleUser.created_at.toString()),
                        'EEEE, dd MMM yyyy @HH:mm',
                      )}
                    </td>
                    {/* <td>
                      <SmallButton onClick={() => handleShowOrders()}>
                        Show Orders
                      </SmallButton>
                    </td> */}
                    <td>Delete Disable</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </TableContainer>
        </Content>
      </Main>
    </div>
  );
};

export default AdminOrders;
