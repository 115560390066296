import axios, {AxiosResponse} from 'axios';
import { requestNewAccessToken } from '../utils/tokenHelpers';


const api = axios.create({
  baseURL: process.env.REACT_APP_URL_API,
});

// Response interceptor for API calls
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error): Promise<AxiosResponse> {
    const originalRequest = error.config;

    if (error?.response?.status === 401 && !originalRequest.retry) {
      originalRequest.retry = true;

      const access_token = await requestNewAccessToken();
      axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;

      localStorage.setItem('@Vispunt:token', access_token);

      return api(originalRequest);
    }
    return Promise.reject(error);
  },
);

api.interceptors.request.use((req) => {
  const access_token = localStorage.getItem('@Vispunt:token');
  req.headers.authorization = `Bearer ${access_token}`;
  return req;
});

// api.interceptors.request.use(async (req) => {
//   const tokenFromLocalStorage = localStorage.getItem('@Vispunt:token');
//   console.log('tokenFromLocalStorage', tokenFromLocalStorage);

//   console.log('tokenFromLocalStorage2', localStorage.getItem('@Vispunt:token'));

//   console.log('--- API INTERCEPTORS REQUEST ---');
//   if (tokenFromLocalStorage !== null && isTokenExpired(tokenFromLocalStorage)) {
//     console.log(`-----===== isTokenExpired --------==--=======`);
//     // Requesting new Access Token
//     const access_token = await requestNewAccessToken();
//     console.log('step1', access_token);
//     // Storing Headers + LocalStorage
//     req.headers.authorization = `Bearer ${access_token}`;
//     localStorage.setItem('@Vispunt:token', access_token);
//     console.log('step2', localStorage.getItem('@Vispunt:token'));

//     console.log('------------ ACCESS TOKEN', access_token);
//   }

//   return req;
// });

export default api;
