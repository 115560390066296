import styled from 'styled-components';

export const ArticleContainer = styled.div`
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  padding-bottom: 120px;
  line-height: 1.8em;

  @media (max-width: 768px) {
    padding: 5em 5em 120px 5em;
  }

  @media (max-width: 576px) {
    padding: 2em 2em 120px 2em;
  }


  h1 {
    margin-bottom: 1em;
  }

  h3 {
    margin: 2em 0 1em;
  }

  p {
    margin: 0 0 0.7em 0.7em;
  }
`;

export const Main = styled.div`
  /* margin-top: -150px; */
`;

export const ContainerMain = styled.div`
  height: 100%;
  position: relative;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(235, 247, 251, 1) 50%,
    rgba(207, 242, 255, 1) 100%
  );
`;
