import React, { useCallback, useEffect, useState } from 'react';
import FloatingCart from '../../components/FloatingCart';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Product from '../../components/Product';
import SearchProducts from '../../components/SearchProducts';
import { useAuth } from '../../hooks/auth';
import { useCart } from '../../hooks/cart';
import { useProducts } from '../../hooks/products';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import formatValue from '../../utils/formatValue';
import { ContainerMain, Main, ProductsContainer } from './styles';

interface IProduct {
  id: string;
  name: string;
  description: string;
  price: number;
  price_label: string;
  image: string;
  image_url: string;
  hashtags: string;
  available: boolean;
  category: ICategory;
  category_id: string;
}

interface ICategory {
  id: string;
  name: string;
  description: string;
}

const Dashboard: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const { addToCart } = useCart();
  const { categoryId } = useProducts();

  const [products, setProducts] = useState<IProduct[]>([]);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [editModalOpen] = useState(false);
  const productsFiltered = categoryId
    ? products.filter((product) => product.category.id === categoryId)
    : products;

  useEffect(() => {
    async function loadProducts(): Promise<void> {
      const { data } = await api.get('/products');

      setProducts(data);
    }

    async function loadCategories(): Promise<void> {
      const { data } = await api.get('/categories');
      setCategories(data);
    }

    loadProducts();
    loadCategories();
  }, []);

  async function handleToggleAvailability(product: IProduct): Promise<void> {
    try {
      const { id, available } = product;

      await api.patch(`/products/availability/${id}`, {
        available,
      });

      const textAvailability = available ? 'available' : 'not available';

      addToast({
        type: 'success',
        title: `Product's Availability Updated`,
        description: `You've made ${textAvailability} the product ${product.name}.`,
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: `Error Changing Availability's Product`,
        description: `You've got an error trying to update a product's availability. Please contact the Admin. ${error.message}.`,
      });
    }
  }

  async function handleDeleteProduct(id: string): Promise<void> {
    try {
      await api.delete(`/products/${id}`);

      const updatedState = products.filter((product) => product.id !== id);

      setProducts(updatedState);

      addToast({
        type: 'success',
        title: `Product Deleted`,
        description: `You've deleted a product.`,
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: `Error Deleting Product`,
        description: `You've got an error trying to delete a product. Please contact the Admin. ${error.message}.`,
      });
      console.error(
        `You've got an error trying to delete the product's ID: ${id}. Please contact the Admin. ${error.message}.`,
      );
    }
  }

  const handleAddProductToCart = useCallback(
    (product: IProduct) => {
      if (user) {
        addToCart({ ...product, quantity: 1 });
        addToast({
          title: 'Product Added',
          description: `You've added ${product.name} ${formatValue(
            product.price,
          )} to your Shopping Cart.`,
        });
        return;
      }

      addToast({
        type: 'info',
        title: `Registration/Login required.`,
        description: `You need to register or login to be able to buy.`,
      });
    },
    [addToCart, addToast],
  );

  return (
    <ContainerMain>
      <Header isModalOpen={editModalOpen} />
      <Main>
        <SearchProducts />
        <FloatingCart />
        <ProductsContainer>
          {productsFiltered &&
            productsFiltered.map((product) => (
              <Product
                key={product.id}
                product={product}
                handleDelete={handleDeleteProduct}
                handleToggleAvailability={handleToggleAvailability}
                handleAddProductToCart={handleAddProductToCart}
              />
            ))}
        </ProductsContainer>
      </Main>
      <Footer />
    </ContainerMain>
  );
};

export default Dashboard;
