import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.header`
  padding: 32px;
  background: #e7f1ff;
`;

export const HeaderContent = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > img {
    height: 80px;
  }

  button {
    background: transparent;
    border: 0;
    margin-left: 2em;

    svg {
      color: #646464;
      width: 20px;
      height: 20px;
    }
  }
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
  margin-left: 80px;

  img {
    width: 56px;
    height: 56px;
    border-radius: 50%;
  }

  div {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    line-height: 24px;

    span {
      color: #646464;
    }

    a {
      text-decoration: none;
      color: #3d6db6;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

export const Content = styled.main`
  max-width: 1120px;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
`;

export const Product = styled.div`
  max-width: 340px;
  width: 32%;
  margin: 0 auto;
  border: 1px solid #ebebeb;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 2em;
  opacity: 0.9;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
    cursor: pointer;
  }

  img {
    width: 100%;
  }
`;

export const ProductPrice = styled.span`
  background: #36bdef;
  padding: 2px 6px;
  color: #4b4b4b;
  border-radius: 5px;

  small {
    margin-left: 3px;
  }
`;

export const ProductTitle = styled.span`
  font-size: 20px;

  span {
    color: #3d6db6;
    font-weight: bold;
  }

  small {
    margin-left: 0.2em;
  }
`;

export const ProductHeader = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`;

export const ProductDetailsContainer = styled.div`
  color: #8d8d8d;
  background: aliceblue;
  padding: 2px;

  span {
    display: flex;
    flex: 1;
  }

  span + span {
    font-size: 13px;
  }
`;

export const UserCreditInfo = styled.div`
  font-size: 14px;

  strong {
    font-size: 16px;
  }
`;

export const CheckoutInfo = styled.div`
  max-width: 1020px;
  margin: 0 auto;
  background: #fff;
  padding: 22px 32px;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  color: #363f5f;
  line-height: 25px;
  /* box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2); */

  h1 {
    margin-bottom: 0.7em;
  }
`;
