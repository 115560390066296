import React, { useRef, useCallback, useState, useEffect } from 'react';
import { FiCheckSquare } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Select from '../Select';
import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErrors';

import { Form, TitleContainer } from './styles';
import Modal from '../Modal';
import Input from '../Input';
import api from '../../services/api';

interface IProduct {
  id: string;
  name: string;
  description: string;
  price: number;
  price_label: string;
  image: string;
  hashtags: string;
  available: boolean;
  category_id: string;
}

interface ICreateProductData {
  name: string;
  description: string;
  price: number;
  price_label: string;
  image: string;
  hashtags: string;
  available: boolean;
  category_id: string;
}

interface ICategory {
  id: string;
  name: string;
  description: string;
}

interface ISelectOptions {
  value: string;
  label: string;
}

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
}

const ModalAddProduct: React.FC<IModalProps> = ({ isOpen, setIsOpen }) => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const [products, setProducts] = useState<IProduct[]>([]);
  const [categories, setCategories] = useState<ISelectOptions[]>();

  useEffect(() => {
    async function loadCategories(): Promise<void> {
      const { data } = await api.get('/categories');
      const categoryOptions = data.map((cat: ICategory) => ({
        value: cat.id,
        label: cat.name,
      }));
      setCategories(categoryOptions);
    }

    loadCategories();
  }, [setCategories]);

  const handleAddProduct = useCallback(
    async (product: Omit<IProduct, 'id' | 'available'>): Promise<void> => {
      try {
        const newProduct = {
          ...product,
          available: true,
        };

        const response = await api.post('/products', newProduct);
        setProducts([...products, response.data]);

        addToast({
          type: 'success',
          title: 'Product Added',
          description: `You've added ${product.name}.`,
        });
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Error Adding Product',
          description: `You've got an error trying to add a product. Please contact the Admin. ${error.message}.`,
        });
      }
    },
    [addToast, products],
  );

  const handleSubmit = useCallback(
    async (data: ICreateProductData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('Name is required.'),
          description: Yup.string().required('Description required'),
          price: Yup.number().required('Price required.'),
          price_label: Yup.string().required('Price label required'),
          image: Yup.string().required('Image required.'),
          hashtags: Yup.string(),
          category_id: Yup.string().required('Category required.'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        handleAddProduct(data);
        setIsOpen();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
      }
    },
    [handleAddProduct, setIsOpen],
  );

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <TitleContainer>
          <h1>New Product</h1>
          <button type="submit">
            <p className="text">Add Product</p>
            <div className="icon">
              <FiCheckSquare size={24} />
            </div>
          </button>
        </TitleContainer>

        <Input name="image" placeholder="Image: Paste the url here" />

        <Input name="name" placeholder="Name: Salmon Filet" />
        <Input name="description" placeholder="Description: Wild Salmon" />
        <Select
          name="category_id"
          placeholder="Category"
          options={categories}
          noOptionsMessage={() => 'No Categories Found.'}
        />
        <Input name="hashtags" placeholder="Hashtags: #filet #salmon" />
        <Input name="price" placeholder="Price: 19.90" />
        <Input name="price_label" placeholder="Price Label: per Kg" />
      </Form>
    </Modal>
  );
};

export default ModalAddProduct;
