import styled from 'styled-components';

import { shade } from 'polished';

export const Container = styled.button`
  background: #3d6db6;
  height: 40px;
  border-radius: 4px;
  border: 0;
  padding: 0 16px;
  color: #fcfeff;
  width: 100%;
  font-weight: 500;
  margin-top: 16px;
  transition: background-color 0.2s;

  &:disabled {
    background: #d5d5d5;
    color: #fcfeff;
  }

  &:hover {
    background: ${shade(0.2, '#3d6db6')};
  }
`;

export const ContainerButtonIcon = styled.button`
  background: white;
  border-radius: 10px;
  border: 0;
  padding: 0 16px;
  color: #fcfeff;
  width: 100%;
  font-weight: 500;
  transition: background-color 0.2s;

  padding: 10px;
  border-radius: 8px;
  display: flex;
  border: none;
  transition: 0.1s;

  svg {
    color: #3d3d4d;
  }

  & + button {
    margin-left: 6px;
  }

  &:hover {
    background: ${shade(0.2, '#3d6db6')};
  }

  &.yellowButton {
    color: #3d6db6;
    background: #fee606;

    span {
      position: absolute;
      margin: -24px 0 0 21px;
      font-size: 16px;
      font-weight: bold;
      background: #3d6db6;
      color: #fee606;
      padding: 0px 6px;
      border-radius: 4px;
    }
  }
`;
