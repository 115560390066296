import React, { useState, useEffect } from 'react';

import { useRouteMatch } from 'react-router-dom';
import 'react-day-picker/lib/style.css';
import { useHistory } from 'react-router-dom';
import { Container, Content } from './styles';

import CheckoutCard from '../../components/CheckoutCard';

import { useToast } from '../../hooks/toast';
import { useCart } from '../../hooks/cart';
import { useAuth } from '../../hooks/auth';

import api from '../../services/api';

import Header from '../../components/Header';

interface IOrder {
  id: string;
  products: string;
  user_id: string;
  status: string;
  total: number;
  delivery_date: Date;
  delivery_instructions: string;
  delivered: boolean;
  created_at: Date;
  updated_at: Date;
}

interface IRequest {
  order_id: string;
}

const Checkout: React.FC = () => {
  const { user } = useAuth();
  const { clean } = useCart();
  const { params } = useRouteMatch<IRequest>();
  const [, setPayment] = useState();
  const history = useHistory();
  const [, setOrders] = useState<IOrder[]>([]);

  const { addToast } = useToast();

  useEffect(() => {
    api.get<IOrder[]>('/orders').then((response) => {
      setOrders(response.data);
    });

    async function checkPayment(): Promise<void> {
      const { data } = await api.get(`/payments/${params.order_id}`);
      setPayment(data);

      if (data.status === 'paid') {
        clean();

        addToast({
          type: 'success',
          title: 'Payment received',
          description:
            'Your payment was succeed. If you need any extra information do not hesitate to contact us.',
        });

        history.push('/orders');
      } else {
        addToast({
          type: 'error',
          title: 'Error processing your payment.',
          description: `You can try again or if you need help please contact us.`,
        });
      }
    }

    if (params.order_id) {
      checkPayment();
    }
  }, [params.order_id, user.id, setOrders, addToast, clean, history]);

  return (
    <Container>
      <Header isModalOpen={false} />

      <Content>
        <CheckoutCard />
      </Content>
    </Container>
  );
};

export default Checkout;
