import React, { useState, useEffect } from 'react';

import { FiEdit3, FiTrash, FiShoppingCart } from 'react-icons/fi';
import formatValue from '../../utils/formatValue';
import { useAuth } from '../../hooks/auth';
import ModalEditProduct from '../ModalEditProduct';
import { ButtonIcon } from '../Button';

import {
  Container,
  ProductName,
  ProductDescription,
  ProductHashtags,
  ProductCategory,
  ProductPrice,
  TitleContainer,
  SemiFooterContainer,
  ButtonExpand,
} from './styles';
// import { ProductDetailsContainer } from '../OrdersList/styles';

interface ICategory {
  id: string;
  name: string;
  description: string;
}

interface IProduct {
  id: string;
  name: string;
  description: string;
  price: number;
  price_label: string;
  image: string;
  image_url: string;
  hashtags: string;
  available: boolean;
  category: ICategory;
  category_id: string;
}

// interface IProductAvailability {
//   id: string;
//   available: boolean;
// }

interface IProps {
  product: IProduct;
  handleDelete: (id: string) => {};
  handleToggleAvailability: (product: IProduct) => void;
  handleAddProductToCart: (product: IProduct) => void;
}

const Product: React.FC<IProps> = ({
  product,
  handleDelete,
  handleToggleAvailability,
  handleAddProductToCart,
}: IProps) => {
  const [isAvailable, setIsAvailable] = useState(product.available);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [prod] = useState(product);
  const [hashtagsSpans, setHashtagsSpans] = useState();
  const { user } = useAuth();
  const [active, setActive] = useState(false);

  async function toggleAvailable(): Promise<void> {
    prod.available = !isAvailable;
    handleToggleAvailability(prod);
    setIsAvailable(!isAvailable);
  }

  async function handleExpandDescription(): Promise<void> {
    setActive(!active);
  }

  useEffect(() => {
    const arrayHashtags = prod.hashtags.split(' ');
    setHashtagsSpans(
      arrayHashtags.map((hashtag) => <span key={hashtag}>{hashtag}</span>),
    );
  }, [prod]);

  return (
    <>
      {user && user.admin ? (
        <Container available={isAvailable}>
          <header>
            <img src={prod.image_url} alt={prod.name} />
          </header>
          <section className="body">
            <TitleContainer>
              <ProductName>{prod.name}</ProductName>
            </TitleContainer>
            <ProductCategory>{prod.category.name}</ProductCategory>
            <ProductPrice onClick={() => handleAddProductToCart(prod)}>
              <b>{formatValue(Number(prod.price))}</b>
              <small> {prod.price_label}</small>
            </ProductPrice>
            <SemiFooterContainer>
              <ProductHashtags>{hashtagsSpans}</ProductHashtags>
              <div>
                <ButtonIcon
                  type="button"
                  className="yellowButton"
                  onClick={() => handleAddProductToCart(prod)}
                >
                  <span>+</span>
                  <FiShoppingCart size={20} />
                </ButtonIcon>
              </div>
            </SemiFooterContainer>
            <ProductDescription active={active}>
              {prod.description}
            </ProductDescription>
            {prod.description.length > 45 && (
              <ButtonExpand onClick={handleExpandDescription}>
                {active ? '-' : '+'}
              </ButtonExpand>
            )}
          </section>
          {user && user.admin && (
            <section className="footer">
              <div className="icon-container">
                <>
                  <ButtonIcon
                    type="button"
                    className="icon"
                    onClick={() => setEditModalOpen(true)}
                    data-testid={`edit-product-${prod.id}`}
                  >
                    <FiEdit3 size={15} />
                  </ButtonIcon>

                  <ButtonIcon
                    type="button"
                    className="icon"
                    onClick={() => handleDelete(prod.id)}
                    data-testid={`remove-product-${prod.id}`}
                  >
                    <FiTrash size={15} />
                  </ButtonIcon>
                </>
              </div>
              <div className="availability-container">
                <p>{isAvailable ? 'Available' : 'Unavailable'}</p>

                <label
                  htmlFor={`available-switch-${prod.id}`}
                  className="switch"
                >
                  <input
                    id={`available-switch-${prod.id}`}
                    type="checkbox"
                    checked={isAvailable}
                    onChange={toggleAvailable}
                    data-testid={`change-status-product-${prod.id}`}
                  />
                  <span className="slider" />
                </label>
              </div>
            </section>
          )}
          <ModalEditProduct
            isOpen={editModalOpen}
            setIsOpen={() => setEditModalOpen(!editModalOpen)}
            editProduct={prod}
          />
        </Container>
      ) : (
        <>
          {isAvailable && (
            <Container available={isAvailable}>
              <header>
                <img src={prod.image_url} alt={prod.name} />
              </header>
              <section className="body">
                <TitleContainer>
                  <ProductName>{prod.name}</ProductName>
                </TitleContainer>
                <ProductCategory>{prod.category.name}</ProductCategory>
                <ProductPrice onClick={() => handleAddProductToCart(prod)}>
                  <b>{formatValue(Number(prod.price))}</b>
                  <small> {prod.price_label}</small>
                </ProductPrice>
                <SemiFooterContainer>
                  <ProductHashtags>{hashtagsSpans}</ProductHashtags>
                  <div>
                    <ButtonIcon
                      type="button"
                      className="yellowButton"
                      onClick={() => handleAddProductToCart(prod)}
                    >
                      <span>+</span>
                      <FiShoppingCart size={20} />
                    </ButtonIcon>
                  </div>
                </SemiFooterContainer>
                <ProductDescription active={active}>
                  {prod.description}
                </ProductDescription>
                {prod.description.length > 45 && (
                  <ButtonExpand onClick={handleExpandDescription}>
                    {active ? '-' : '+'}
                  </ButtonExpand>
                )}
              </section>
            </Container>
          )}
        </>
      )}
    </>
  );
};

export default Product;
