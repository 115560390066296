import styled from 'styled-components';

export const Container = styled.footer`
  position: fixed;
  height: fit-content;
  bottom: 0;
  width: 100%;
  display: flex;
  font-size: 14px;
  color: #d3f3ff;
  justify-content: center;
  background: linear-gradient(
    180deg,
    rgb(61 109 182) 0%,
    rgb(68 111 176) 50%,
    rgb(61 109 182) 100%
  );

  @media (max-width: 767.98px) {
    position: absolute;
  }

  a {
    color: #fce835;
    text-decoration: none;
    padding: 0 4px;
    transition: background-color 0.2s;

    &:hover {
      color: #406eb3;
      text-decoration: none;
      background: #f3fbfd;
      border-radius: 3px;
    }
  }
`;

export const InsideContainer = styled.div`
  padding: 1em 2em;
  max-width: 1280px;
  flex-basis: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 767.98px) {
    flex-direction: column;
  }

  span.city {
    color: #fee606;
  }

  div {
    padding: 0px 4px;
    border-radius: 2px;
    font-weight: 500;
    background: #4875b6;

    @media (max-width: 767.98px) {
      margin-bottom: 2em;
      padding: 1em;
    }
  }
`;

export const AddressContainer = styled.div`
  display: flex;
  align-items: center;

  address {
    display: flex;
    flex-direction: column;
  }

  span.location {
    color: #fee606;
    margin-right: 1em;
  }
`;
