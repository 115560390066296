import styled from 'styled-components';

export const ProductsContainer = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 40px 0 100px;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

export const Main = styled.div`
  @media (max-width: 767.98px) {
    padding-bottom: 370px;
  }
`;

export const ContainerMain = styled.div`
  height: 100%;
  position: relative;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(235, 247, 251, 1) 50%,
    rgba(207, 242, 255, 1) 100%
  );
`;
