const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#5506B0' : 'white',
  }),
  control: (provided: any) => ({
    ...provided,
    border: 0,
    height: '57px',
    borderRadius: '10px',
    background: '#d5e6ff',
    marginBottom: '8px',
    color: '#a2c9fe',
  }),
};

export default customStyles;
