import React from 'react';
import {
  RouteProps as ReactDOMRouteProps,
  Route as ReactDOMRoute,
  Redirect,
} from 'react-router-dom';

import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import { useAuth } from '../hooks/auth';

interface IRouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  isAdmin?: boolean;
  component: React.ComponentType;
}

const Route: React.FC<IRouteProps> = ({
  isPrivate = false,
  isAdmin = false,
  component: Component,
  ...rest
}) => {
  const { user } = useAuth();

  const history = createBrowserHistory();

  // Initialize google analytics page view tracking
  history.listen((location) => {
    ReactGA.initialize('your tracking Id');
    ReactGA.set({ page: location.location.pathname });
    ReactGA.pageview(location.location.pathname);
  });

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return isPrivate && !user ? (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        ) : (
          <Component />
        );
      }}
    />
  );
};

export default Route;
