import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.header`
  padding: 32px;
  background: #e7f1ff;
`;

export const HeaderContent = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > img {
    height: 80px;
  }

  button {
    background: transparent;
    border: 0;
    margin-left: 2em;

    svg {
      color: #646464;
      width: 20px;
      height: 20px;
    }
  }
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
  margin-left: 80px;

  img {
    width: 56px;
    height: 56px;
    border-radius: 50%;
  }

  div {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    line-height: 24px;

    span {
      color: #646464;
    }

    a {
      text-decoration: none;
      color: #3d6db6;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

export const Content = styled.main`
  max-width: 1120px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  background: #f6fdff;
  padding: 0.5em 1em;
  border-radius: 10px;
  border: 2px solid #e2f7fe;
`;

export const ContentDetails = styled.main`
  max-width: 1120px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  margin-bottom: 2em;
`;

export const Product = styled.div`
  max-width: 340px;
  width: 32%;
  margin: 0 auto;
  border: 1px solid #ebebeb;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 2em;
  opacity: 0.9;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
    cursor: pointer;
  }

  img {
    width: 100%;
  }
`;

export const ProductPrice = styled.span`
  background: #36bdef;
  padding: 2px 6px;
  color: #4b4b4b;
  border-radius: 5px;

  small {
    margin-left: 3px;
  }
`;

export const ProductTitle = styled.span`
  font-size: 20px;

  span {
    color: #3d6db6;
    font-weight: bold;
  }

  small {
    margin-left: 0.2em;
  }
`;

export const ProductHeader = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`;

export const ProductDetailsContainer = styled.div`
  color: #8d8d8d;
  background: aliceblue;
  padding: 2px;

  span {
    display: flex;
    flex: 1;
  }

  span + span {
    font-size: 13px;
  }
`;

export const UserCreditInfo = styled.div`
  font-size: 14px;

  strong {
    font-size: 14px;
  }
`;

export const CheckoutInfo = styled.div`
  max-width: 1020px;
  margin: 0 auto;
  background: #fff;
  padding: 22px 32px;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  color: #363f5f;
  line-height: 25px;
  /* box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2); */

  h1 {
    margin-bottom: 0.7em;
  }
`;

export const TableContainer = styled.section`
  p {
    flex: 1;
    justify-content: space-between;
    display: flex;
  }

  span.total {
    font-size: 20px;
    font-weight: bold;
  }

  table {
    width: 100%;
    border-spacing: 0 8px;

    th {
      color: #969cb3;
      font-weight: normal;
      text-align: left;
      font-size: 12px;
      padding-right: 0.5em;

      &:first-child {
        width: 60%;
      }

      &:last-child {
        /* width: 10%; */
        text-align: center;
        min-width: 20px;
      }
    }

    tr {
      &.active {
        td {
          background: #fee606;
        }
      }

      &.failed {
        td {
          color: red;
        }
      }

      &.waiting-delivery {
        td {
          color: #4f7cbe;
        }
      }

      &.delivered {
        td {
          color: green;
        }
      }
    }

    td {
      white-space: nowrap;
      border: 0;
      padding: 0.2em 0.6em;
      font-size: 14px;
      font-weight: normal;
      color: #3c3c3c;

      &.title {
        color: #363f5f;
      }

      &:first-child {
        padding: 0 0 0 1em;
        border-radius: 4px 0 0 4px;
      }

      &:last-child {
        border-radius: 0 4px 4px 0;
        text-align: center;
      }

      &.small {
        font-size: 13px;
      }
    }
  }
`;

export const Alert = styled.span`
  color: red;
  font-weight: bold;
`;

export const SmallButton = styled.button`
  background: #3d6db6;
  color: #ffffff;
  border: 0ch;
  padding: 5px 10px;
  border-radius: 5px;
  opacity: 0.9;
  transition: opacity 0.2s;
  font-size: 13px;
  margin-right: 1em;

  &:hover {
    opacity: 1;
  }

  &:disabled {
    background: #cff3fe;
    color: #8fa8ce;
  }
`;

export const BigField = styled.div`
  font-size: 1.5em;
`;

export const MediumField = styled.div`
  font-size: 1.25em;
`;

export const SmallField = styled.div`
  font-size: 0.9em;
`;

export const WarningField = styled.div`
  font-size: 1.2em;
  color: orangered;
`;

export const ContactContainer = styled.div`
  background: #f6fdff;
  padding: 0.5em 1em;
  border-radius: 10px;
  border: 2px solid #e2f7fe;
  width: fit-content;
  margin-bottom: 1em;
`;

export const ProductsContainer = styled.div`
  background: #f6fdff;
  width: 80%;
  padding: 0.5em 1em;
  border-radius: 10px;
  border: 2px solid #e2f7fe;
`;
