import React, {
  useRef,
  useCallback,
  useState,
  ChangeEvent,
  useEffect,
} from 'react';

import { FiCheckSquare, FiCamera } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form, ImageInput, TitleContainer } from './styles';
import Modal from '../Modal';
import Input from '../Input';
import Select from '../Select';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';

interface IProduct {
  id: string;
  name: string;
  description: string;
  price: number;
  price_label: string;
  image: string;
  image_url: string;
  hashtags: string;
  available: boolean;
  category_id: string;
}

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  editProduct: IProduct;
}

interface IEditProductData {
  name: string;
  description: string;
  price: number;
  price_label: string;
  image: string;
  image_url: string;
  hashtags: string;
  available: boolean;
  category_id: string;
}

interface ISelectOptions {
  value: string;
  label: string;
}

interface ICategory {
  id: string;
  name: string;
  description: string;
}

type OptionType = {
  value: string;
  label: string;
};

const ModalEditProduct: React.FC<IModalProps> = ({
  isOpen,
  setIsOpen,
  editProduct,
}) => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const [products, setProducts] = useState<IProduct[]>([]);
  const [categories, setCategories] = useState<ISelectOptions[]>();
  const [selectedCategory, setSelectedCategory] = useState<OptionType>();
  const [product, setProduct] = useState<IProduct>(editProduct);

  useEffect(() => {
    async function loadCategories(): Promise<void> {
      const { data } = await api.get('/categories');
      const categoryOptions = data.map((cat: ICategory) => ({
        value: cat.id,
        label: cat.name,
      }));

      const tmpSel = await data.filter((cat: ICategory) => {
        return cat.id === editProduct.category_id;
      });

      setSelectedCategory({
        value: tmpSel[0].id,
        label: tmpSel[0].name,
      });

      setCategories(categoryOptions);
    }

    loadCategories();
  }, [editProduct.category_id, setCategories]);

  const handleUpdateProduct = useCallback(
    async (product: Omit<IProduct, 'id' | 'available'>): Promise<void> => {
      try {
        const { id, available, name } = editProduct;

        const updatedProduct = {
          ...product,
          available,
        };

        const response = await api.put(`/products/${id}`, updatedProduct);

        const updatedState = products.filter((item) => item.id !== id);

        setProducts([...updatedState, response.data]);

        addToast({
          type: 'success',
          title: 'Product Updated',
          description: `You've updated ${name}.`,
        });
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Error Updating Product',
          description: `You've got an error trying to update a product. Please contact the Admin. ${error.message}.`,
        });
      }
    },
    [addToast, editProduct, products],
  );

  const handleChangeCategory = (option: any) => {
    setSelectedCategory(option);
  };

  const handleChangeImage = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const data = new FormData();
        const { id } = product;

        data.append('image', e.target.files[0]);
        api.patch(`product-images/${id}`, data).then((response) => {
          const updatedProduct = response.data;
          const updatedState = products.filter(
            (item) => item.id !== updatedProduct.id,
          );
          setProducts([...updatedState, updatedProduct]);
          setProduct(updatedProduct);
          addToast({
            type: 'success',
            title: 'Image Updated!',
          });
        });
      }
    },
    [addToast, setProduct, product, products],
  );

  const handleSubmit = useCallback(
    async (data: IEditProductData) => {
      setIsOpen();
      // UpdateImage if changed
      // handleChangeImage(data);
      // UpdateProduct
      handleUpdateProduct(data);
    },
    [handleUpdateProduct, setIsOpen],
  );

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Form ref={formRef} onSubmit={handleSubmit} initialData={product}>
        <TitleContainer>
          <h1>Edit Product</h1>
          <button type="submit" data-testid="edit-product-button">
            <div className="text">Save</div>
            <div className="icon">
              <FiCheckSquare size={24} />
            </div>
          </button>
        </TitleContainer>
        <ImageInput>
          <img src={product.image_url} alt={product.name} />
          <label htmlFor="image">
            <FiCamera />

            <input
              type="file"
              name=""
              id="image"
              onChange={handleChangeImage}
            />
          </label>
        </ImageInput>
        <Input name="name" placeholder="Salmon Filet" />
        <Input name="description" placeholder="Wild Salmon" />

        <Select
          name="category_id"
          placeholder="Category"
          options={categories}
          onChange={(option) => handleChangeCategory(option)}
          noOptionsMessage={() => 'No Categories Found.'}
          value={selectedCategory}
        />

        <Input name="hashtags" placeholder="Salmon Filet" />
        <Input name="price" placeholder="19.90" />
        <Input name="price_label" placeholder="per Kg" />
      </Form>
    </Modal>
  );
};

export default ModalEditProduct;
