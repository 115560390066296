import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

interface ICardProps {
  total?: boolean;
  totalItensInCart?: number;
}

interface IButtonExpandProps {
  active: boolean;
}

export const ProductName = styled.div`
  /* width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
`;

export const ProductPrice = styled.span`
  span {
    font-weight: bold;
  }
`;

export const ShoppingCartContainer = styled(animated.div)<ICardProps>`
  min-width: 500px;
  position: fixed;
  bottom: 0;
  z-index: 1;
  /* width: 35%; */
  margin: 0 0 1em;

  @media (max-width: 768px) {
    min-width: 100%;
  }
`;

export const ShoppingCartCard = styled.div`
  background: ${({ total }: ICardProps): string =>
    total ? '#36bdef' : '#ffffff'};
  padding: 22px 32px;
  border-radius: 10px;
  border: 1px solid #e5e5e5;
  color: ${({ total }: ICardProps): string => (total ? '#fff' : '#363F5F')};

  -webkit-box-shadow: 0px 10px 13px -7px #000000,
    33px -2px 35px -5px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 10px 13px -7px #000000,
    33px -2px 35px -5px rgba(0, 0, 0, 0.08);

  @media (max-width: 767.98px) {
    padding: 10px;
    margin: 5px;
  }
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-size: 16px;

      @media (max-width: 768px) {
        font-size: 12px;
      }
    }
  }

  h1 {
    margin-top: 14px;
    font-size: 36px;
    font-weight: normal;
    line-height: 54px;
    font-weight: bold;
    text-align: right;
  }
`;

export const ShoppingCartList = styled.div`
  background: lightgrey;
`;

export const ShoppingCartTotal = styled.div`
  background: lightgrey;
`;

export const Button = styled.button`
  background: transparent;
  border: 0;

  &.red {
    svg {
      color: red;
    }
  }

  &.green {
    svg {
      color: #36bdef;
    }

    margin-left: 3px;
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const TotalItemsLabel = styled.span`
  background: #fee606;
  color: #3d6db6;
  padding: 2px 4px;
  border-radius: 3px;
`;

export const ButtonCheckout = styled.button`
  background: #fee606;
  color: #3d6db6;
  border: 0ch;
  padding: 5px 10px;
  border-radius: 3px;
  opacity: 0.9;
  transition: opacity 0.2s;
  text-transform: uppercase;
  font-weight: bold;

  &:hover {
    opacity: 1;
  }

  &:disabled {
    background: #969cb3;
    color: #fee606;
  }

  @media (min-width: 768px) {
    padding: 7px 12px;
  }
`;

export const ButtonPay = styled.button`
  background: #3d6db6;
  color: #fee606;
  border: 0ch;
  padding: 5px 10px;
  border-radius: 3px;
  opacity: 0.9;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }
`;

export const MinOrderMessage = styled.span`
  color: orange;
  font-size: 16px;
  font-weight: bold;
  margin-right: 1em;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export const TableContainer = styled.section`
  p {
    flex: 1;
    justify-content: space-between;
    display: flex;
  }

  span.total {
    font-size: 20px;
    font-weight: bold;
    margin-right: 0.7em;

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  table {
    width: 100%;
    border-spacing: 0 8px;

    th {
      color: #969cb3;
      font-weight: normal;
      text-align: left;
      font-size: 12px;
      min-width: 17%;

      span {
        background: #ffe83c;
        border-radius: 2px;
        padding: 1px 4px;
      }

      &.price {
        width: 17%;
        padding-right: 0.4rem;
      }

      &:first-child {
        min-width: 60%;
      }

      &:last-child {
        width: 10%;
        text-align: center;
        min-width: 20px;
      }
    }

    td {
      /* padding: 15px 20px; */
      border: 0;
      background: #fff;
      font-size: 14px;
      font-weight: normal;
      color: #969cb3;
      white-space: nowrap;

      @media only screen and (max-width: 768px) {
        font-size: 11px;
      }

      &.title {
        color: #363f5f;
      }

      &.price {
        padding-right: 0.4rem;
      }

      &:first-child {
        border-radius: 8px 0 0 8px;
      }

      &.center {
        text-align: center;
      }

      &:last-child {
        border-radius: 0 8px 8px 0;
        text-align: center;
      }
    }
  }
`;

export const ButtonExpand = styled.span<IButtonExpandProps>`
  background: #37bdef;
  color: #ffffff;
  padding: 3px 4px 0px 3px;
  border-radius: 4px;
  cursor: pointer;
  position: fixed;
  bottom: 0.8rem;
  left: 0.8rem;
  z-index: 2;
  display: flex;
  font-size: 10px;
  align-items: center;
  font-weight: 500;
  padding: 0.3rem 0.5rem;
  border: 1px solid white;

  img {
    height: 20px;
    stroke: red;
    stroke-width: 3px;

    ${(props) =>
      props.active &&
      css`
        transform: rotate(180deg);
      `};
  }
`;
