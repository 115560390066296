import styled from 'styled-components';

export const Container = styled.div`
  padding: 0;
  border-bottom: 2px solid #edf7fb;
  margin-bottom: 1rem;

  header {
    nav {
      div {
        button {
          font-weight: 600;
          border-radius: 8px;
          border: 0;
          background: #36bdef;
          color: #fff;

          display: flex;
          flex-direction: row;
          align-items: center;

          .text {
            padding: 16px 24px;
          }

          .icon {
            display: flex;
            padding: 16px 16px;
            background: #36bdef;
            border-radius: 0 8px 8px 0;
            margin: 0 auto;
          }
        }
      }
    }
  }
`;

export const Header = styled.header`
  padding: 32px;
  background: #e7f1ff;
`;

export const HeaderContent = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  padding: 0.5em;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & img {
    height: 80px;
  }

  @media (max-width: 767.98px) {
    & img {
      height: 30px;
    }
  }

  button {
    background: transparent;
    border: 0;
    margin-left: 16px;
    color: #3d6db6;
    transition: color 0.4s;

    &:hover {
      color: #3d6db6;
    }
  }
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;

  img {
    width: 56px;
    height: 56px;
    border-radius: 50%;
  }

  @media (max-width: 767.98px) {
    margin: 0 0.5em;
    font-size: 11px;

    strong {
      font-size: 11px;
    }

    svg {
      width: 12px;
      height: 12px;
    }
  }

  @media (max-width: 400px) {
    .nomobile {
      display: none;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    line-height: 24px;

    span {
      color: #646464;
    }

    a {
      text-decoration: none;
      color: #3d6db6;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

export const Content = styled.main`
  max-width: 1120px;
  margin: 64px auto;
  display: flex;
  flex-direction: column;
`;

export const ProductsList = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
`;

export const Product = styled.div`
  max-width: 340px;
  width: 32%;
  margin: 0 auto;
  border: 1px solid #ebebeb;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 2em;
  opacity: 0.9;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
    cursor: pointer;
  }

  img {
    width: 100%;
  }
`;

export const ProductPrice = styled.span`
  background: #36bdef;
  padding: 2px 6px;
  color: #4b4b4b;
  border-radius: 5px;

  small {
    margin-left: 3px;
  }
`;

export const ProductTitle = styled.span`
  font-size: 20px;

  span {
    color: #3d6db6;
    font-weight: bold;
  }

  small {
    margin-left: 0.2em;
  }
`;

export const ProductHeader = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`;

export const ProductDetailsContainer = styled.div`
  color: #8d8d8d;
  background: aliceblue;
  padding: 2px;

  span {
    display: flex;
    flex: 1;
  }

  span + span {
    font-size: 13px;
  }
`;

export const UserCreditInfo = styled.div`
  font-size: 14px;

  strong {
    font-size: 16px;
  }
`;
