import React from 'react';
import { Link } from 'react-router-dom';

import { Container, AddressContainer, InsideContainer } from './styles';

const Footer: React.FC = () => {
  return (
    <Container>
      <InsideContainer>
        <div>
          <span className="company-name">Vispunt</span>
          <span className="domain">.nl</span> delivering to{' '}
          <span className="city">Den Haag</span> and{' '}
          <span className="city">Rotterdam</span>
        </div>
        <AddressContainer>
          <span className="location">
            Visit us at <b>Haagse Markt</b>:
          </span>
          <address>
            <span>kraam 5.80/5.81, 2571 PR</span>
            <span>Den Haag, Netherlands</span>
          </address>
        </AddressContainer>

        <div>
          <Link to="/cookie-policy">Cookie Policy</Link>
        </div>
        <div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://wa.me/31626264526"
          >
            Contact (WhatsApp)
          </a>
        </div>
      </InsideContainer>
    </Container>
  );
};

export default Footer;
