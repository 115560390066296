import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import CookiePolicy from '../pages/CookiePolicy';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';

import Profile from '../pages/Profile';
import Dashboard from '../pages/Dashboard';
import Checkout from '../pages/Checkout';
import Orders from '../pages/Orders';
import AdminUsers from '../pages/AdminUsers';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={Dashboard} />

    <Route path="/login" exact component={SignIn} />
    <Route path="/signup" exact component={SignUp} />
    <Route path="/forgot-password" exact component={ForgotPassword} />
    <Route path="/reset-password" exact component={ResetPassword} />
    <Route path="/cookie-policy" exact component={CookiePolicy} />

    <Route path="/profile" component={Profile} isPrivate />
    <Route path="/checkout/:order_id" component={Checkout} isPrivate />
    <Route path="/checkout" component={Checkout} isPrivate />
    <Route path="/orders" component={Orders} isPrivate />
    <Route path="/order/:order_id" component={Orders} isPrivate />

    <Route path="/users" component={AdminUsers} isPrivate />
  </Switch>
);

export default Routes;
