import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Transition } from 'react-spring/renderprops';

import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi';

import {
  ShoppingCartContainer,
  ShoppingCartCard,
  TableContainer,
  Button,
  ButtonCheckout,
  MinOrderMessage,
  ProductName,
  ProductPrice,
  ButtonExpand,
} from './styles';

import formatValue from '../../utils/formatValue';
import truncate from '../../utils/truncate';

import { useCart } from '../../hooks/cart';

interface IProduct {
  id: string;
  name: string;
  description: string;
  price: number;
  price_label: string;
  image: string;
  hashtags: string;
  available: boolean;
  category_id: string;
}

const FloatingCart: React.FC = () => {
  const { products, decrement, increment } = useCart();
  const [active, setActive] = useState(true);
  const cartTotal = useMemo(() => {
    if (products.length < 1) {
      return 0;
    }

    const totalPrice = products.reduce(
      (total, product) => total + Number(product.price) * product.quantity,
      0,
    );

    return totalPrice;
  }, [products]);

  const totalItensInCart = useMemo(() => {
    if (products.length < 1) {
      return 0;
    }

    const totalItens = products.reduce(
      (total, product) => total + product.quantity,
      0,
    );

    return totalItens;
  }, [products]);

  async function handleExpand(): Promise<void> {
    setActive(!active);
  }

  return (
    <>
      {totalItensInCart > 0 && (
        <ButtonExpand active={active} onClick={handleExpand}>
          {/* <img src={fishIcon} alt="fish back button" />{' '} */}
          {active
            ? 'hide cart'
            : `show cart ${totalItensInCart} products selected`}
        </ButtonExpand>
      )}
      <Transition
        items={totalItensInCart > 0 && active}
        from={{ left: '-120%', opacity: 0 }}
        enter={{ left: '0%', opacity: 1 }}
        leave={{ left: '-120%', opacity: 0 }}
      >
        {(show) =>
          show &&
          ((props) => (
            <ShoppingCartContainer style={props}>
              <ShoppingCartCard>
                <TableContainer>
                  <table>
                    <thead>
                      <tr>
                        <th className="name">
                          <span>Name</span>
                        </th>
                        <th className="price">
                          <span>Price</span>
                        </th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>

                    <tbody>
                      {products.map((cartProduct) => (
                        <tr key={cartProduct.id}>
                          <td className="name">
                            <ProductName>
                              {truncate(cartProduct.name, 30)}
                            </ProductName>
                          </td>
                          <td className="price">
                            <ProductPrice>
                              <span>{formatValue(cartProduct.price)}</span>
                              <small> x </small>
                              <span>{cartProduct.quantity}</span>
                            </ProductPrice>
                          </td>
                          <td>
                            <Button
                              type="button"
                              className="red"
                              onClick={() => decrement(cartProduct.id)}
                            >
                              <FiMinusCircle />
                            </Button>
                            <Button
                              type="button"
                              className="green"
                              onClick={() => increment(cartProduct.id)}
                            >
                              <FiPlusCircle />
                            </Button>
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan={4} style={{ textAlign: 'right' }}>
                          {cartTotal < 25 && (
                            <MinOrderMessage>
                              Minimum Order &euro; 25
                            </MinOrderMessage>
                          )}
                          Total{' '}
                          <span className="total">
                            {formatValue(Number(cartTotal))}
                          </span>
                          <Link to="/checkout">
                            <ButtonCheckout
                              disabled={cartTotal < 25}
                              className="checkout"
                            >
                              Checkout
                            </ButtonCheckout>
                          </Link>{' '}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </TableContainer>
              </ShoppingCartCard>
            </ShoppingCartContainer>
          ))
        }
      </Transition>
    </>
  );
};

export default FloatingCart;
