import React, { useRef, useCallback, useState } from 'react';

import { FiCheckSquare } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Form } from './styles';
import Modal from '../Modal';
import Input from '../Input';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';
import { TitleContainer } from '../ModalAddProduct/styles';

interface ICategory {
  id: string;
  name: string;
  description: string;
}

interface ICreateCategoryData {
  name: string;
  description: string;
}

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
}

const ModalAddCategory: React.FC<IModalProps> = ({ isOpen, setIsOpen }) => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const [categories, setCategories] = useState<ICategory[]>([]);

  const handleAddCategory = useCallback(
    async (category: Omit<ICategory, 'id'>): Promise<void> => {
      try {
        const response = await api.post('/categories', category);
        setCategories([...categories, response.data]);

        addToast({
          type: 'success',
          title: 'Category Added',
          description: `You've added ${category.name}.`,
        });
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Error Adding Category',
          description: `You've got an error trying to add a category. Please contact the Admin. ${error.message}.`,
        });
      }
    },
    [addToast, categories],
  );

  const handleSubmit = useCallback(
    async (data: ICreateCategoryData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('Name is required.'),
          description: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        handleAddCategory(data);
        setIsOpen();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
      }
    },
    [handleAddCategory, setIsOpen],
  );

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <TitleContainer>
          <h1>New Category</h1>
          <button type="submit">
            <p className="text">Add Category</p>
            <div className="icon">
              <FiCheckSquare size={24} />
            </div>
          </button>
        </TitleContainer>

        <Input name="name" placeholder="Name: Salmon" />
        <Input name="description" placeholder="Description: Salmon" />
      </Form>
    </Modal>
  );
};

export default ModalAddCategory;
