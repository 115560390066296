import React from 'react';
import { Container } from './styles';

const DeliveryInstructions: React.FC = () => {
  return (
    <Container>
      <h3>Where we delivery:</h3>
      <ul>
        <li>Den Haag</li>
        <li>Leidschendam-/Voorburg</li>
        <li>Delft</li>
        <li>Schiedam</li>
      </ul>
    </Container>
  );
};

export default DeliveryInstructions;
