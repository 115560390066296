import styled from 'styled-components';

// Main,
// Content,
// TableContainer,
// SearchContainer,
// SmallButton,

export const Main = styled.div``;

export const Header = styled.header`
  padding: 32px;
  background: #e7f1ff;
`;

export const Content = styled.main`
  max-width: 1120px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background: #f6fdff;
  padding: 0.5em 1em;
  border-radius: 10px;
  border: 2px solid #e2f7fe;
`;

export const TableContainer = styled.section`
  margin-bottom: 2em;

  p {
    flex: 1;
    justify-content: space-between;
    display: flex;
  }

  span.total {
    font-size: 20px;
    font-weight: bold;
  }

  table {
    width: 100%;
    border-spacing: 0 8px;

    th {
      color: #969cb3;
      font-weight: normal;
      text-align: left;
      font-size: 12px;
      padding-right: 0.5em;

      &:last-child {
        width: 10%;
        text-align: center;
        min-width: 20px;
      }
    }

    td {
      white-space: nowrap;
      border: 0;
      font-size: 16px;
      font-weight: normal;
      color: #3c3c3c;
      padding-right: 1em;

      &.title {
        color: #363f5f;
      }

      &:first-child {
        border-radius: 8px 0 0 8px;
      }

      &:last-child {
        border-radius: 0 8px 8px 0;
        text-align: center;
      }

      &.small {
        font-size: 13px;
      }
    }
  }
`;

export const SearchContainer = styled.div``;

export const SmallButton = styled.button`
  background: #3d6db6;
  color: #ffffff;
  border: 0ch;
  padding: 5px 10px;
  border-radius: 5px;
  opacity: 0.9;
  transition: opacity 0.2s;
  font-size: 13px;
  margin-right: 1em;

  &:hover {
    opacity: 1;
  }

  &:disabled {
    background: #cff3fe;
    color: #8fa8ce;
  }
`;
