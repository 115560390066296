import styled from 'styled-components';

interface ICardProps {
  total?: boolean;
}

export const ShoppingCartContainer = styled.div`
  width: 60%;
  display: flex;
  justify-content: space-between;
  max-width: 1280px;
  margin: 0 auto;

  margin-bottom: 2em;

  @media (max-width: 767.98px) {
    width: 98%;
  }
`;

export const InputContainer = styled.div`
  margin: 1em 0;
`;

export const TextArea = styled.textarea`
  font-size: 16px;
  display: block;
  resize: none;
  width: 100%;
  font-family: inherit;

  @media (max-width: 767.98px) {
    font-size: 12px;
  }

  background: #d5e6ff;
  border-radius: 10px;
  padding: 16px;
  border: 2px solid #d5e6ff;
  color: #646464;
  margin-top: 1em;

  ::placeholder {
    color: #a2c8ff;
  }
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #d5e6ff;
  border-radius: 10px;
  padding: 1em;

  span {
    margin-bottom: 0.3em;
  }
`;

export const Label = styled.label`
  margin-bottom: 0.3em;
  line-height: 1.5;
  cursor: pointer;
  font-size: 16px;

  input {
    margin-right: 0.5em;
  }

  small {
    font-weight: bold;
    color: #3d6db6;
  }

  @media (max-width: 767.98px) {
    font-size: 12px;
  }
`;

export const ShoppingCartCard = styled.div`
  width: 100%;
  background: ${({ total }: ICardProps): string =>
    total ? '#36bdef' : '#ffffff'};
  padding: 22px 32px;
  color: ${({ total }: ICardProps): string => (total ? '#fff' : '#363F5F')};

  @media (max-width: 767.98px) {
    padding: 10px;
  }

  p {
    font-size: 16px;
    margin-bottom: 1em;

    @media (max-width: 767.98px) {
      font-size: 12px;
    }
  }

  h1 {
    margin-top: 14px;
    font-size: 36px;
    font-weight: normal;
    line-height: 54px;
  }
`;

export const ShoppingCartList = styled.div`
  background: lightgrey;
`;

export const ShoppingCartTotal = styled.div`
  background: lightgrey;
`;

export const Button = styled.button`
  background: transparent;
  border: 0;

  &.red {
    svg {
      color: red;
    }
  }

  &.green {
    svg {
      color: #36bdef;
    }

    margin-left: 3px;
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const ButtonCheckout = styled.button`
  background: #fee606;
  color: #3d6db6;
  border: 0ch;
  padding: 5px 10px;
  border-radius: 3px;
  opacity: 0.9;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }
`;

export const ButtonPay = styled.button`
  background: #3d6db6;
  color: #fee606;
  border: 0ch;
  padding: 5px 20px;
  font-size: 16px;
  border-radius: 3px;
  opacity: 0.9;
  transition: opacity 0.2s;
  text-transform: uppercase;
  font-weight: bold;

  &:hover {
    opacity: 1;
  }

  @media (max-width: 767.98px) {
    display: block;
    width: 100%;
    margin: 1em 0;
    padding: 10px 0;
  }
`;

export const TableContainer = styled.section`
  margin-bottom: 1em;

  p {
    flex: 1;
    justify-content: space-between;
    display: flex;

    span {
      font-size: 14px;
    }

    small {
      font-size: 11px;
    }
  }

  table {
    width: 100%;
    border-spacing: 0 8px;
    background: #d5e6ff;
    border-radius: 10px;
    padding: 0 10px 0 10px;

    th {
      color: #969cb3;
      font-weight: normal;
      /* padding: 15px 20px; */
      text-align: left;
      font-size: 12px;
      /* line-height: 24px; */

      &:first-child {
        width: 60%;
      }

      &:last-child {
        width: 10%;
        text-align: center;
        min-width: 20px;
      }
    }

    td {
      border: 0;
      font-size: 16px;
      font-weight: normal;
      color: #646464;
      white-space: nowrap;

      @media (max-width: 767.98px) {
        font-size: 12px;
        white-space: normal;
      }

      &.title {
        color: #363f5f;
      }

      &:first-child {
        border-radius: 8px 0 0 8px;
      }

      &:last-child {
        border-radius: 0 8px 8px 0;
        text-align: center;
      }

      &.right {
        text-align: right;
      }
    }
  }
`;

export const IconContainer = styled.span`
  background-color: #f0f8ff;
  color: #507cbe;
  padding: 3px 4px 0px 3px;
  border-radius: 4px;
`;

export const ValueToPay = styled.span`
  background-color: #f0f8ff;
  color: #507cbe;
  padding: 3px 4px 0px 3px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 18px;
`;
