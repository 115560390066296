import styled from 'styled-components';

export const Container = styled.section`
  padding: 0.5em 0em;

  nav {
    max-width: 1100px;
    margin: 0 auto;
  }

  ul {
    list-style-type: none;

    @media (max-width: 767.98px) {
      display: flex;
      flex-direction: column;
    }

    li, a {
      display: flex;
      align-items: center;
      color: #3d6db6;
      padding: 5px 8px 7px;
      font-weight: 500;

      :hover,
      &.active {
        background: #3d6db6;
        border-radius: 4px;
        padding: 5px 8px 7px;
        color: #fee606;
      }
    }

    a {
      text-decoration: none;
    }
  }
`;

export const Menu = styled.ul`
  display: flex;
`;

export const Item = styled.li`
  margin: 0 auto;
  cursor: pointer;

  svg {
    &.active {
      color: #fee606;
    }
  }

  svg {
    margin-right: 0.3em;
  }

  @media only screen and (max-width: 768px) {
    margin-left: 1em;
  }
`;
