import React, { useState, useEffect, useCallback } from 'react';

import 'react-day-picker/lib/style.css';
import { format, parseISO } from 'date-fns';
import {
  Container,
  Content,
  ContentDetails,
  TableContainer,
  SmallButton,
  BigField,
  MediumField,
  WarningField,
  ContactContainer,
  ProductsContainer,
} from './styles';

import formatValue from '../../utils/formatValue';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';

interface IPayment {
  id: string;
  order_id: string;
  amount: number;
  currency: string;
  status: string;
  provider_id: string;
  method: string;
  created_at: Date;
  updated_at: Date;
}

interface ICategory {
  id: string;
  name: string;
  description: string;
  created_at: Date;
  updated_at: Date;
}

interface IProduct {
  id: string;
  owner_id: string;
  category_id: string;
  name: string;
  description: string;
  price: number;
  price_label: string;
  image: string;
  hashtags: string;
  available: true;
  created_at: Date;
  updated_at: Date;
  category: ICategory;
  quantity: number;
}

interface IUser {
  id: string;
  name: string;
  email: string;
  address: string;
  address_number: string;
  address_complement: string;
  address_postcode: string;
  address_city: string;
  address_state: string;
  address_country: string;
  mobile_phone: string;
  delivery_instructions: string;
  created_at: Date;
}

interface IOrder {
  id: string;
  user_id: string;
  user: IUser;
  status: string;
  status_message: string;
  products: IProduct[];
  total: number;
  delivery_date: Date;
  delivery_instructions: string;
  delivered: boolean;
  created_at: Date;
  updated_at: Date;
  payments: IPayment[];
}

interface IRequest {
  order_id: string;
}

const OrdersList: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();

  const [orders, setOrders] = useState<IOrder[]>();
  const [products, setProducts] = useState<IProduct[]>();
  const [orderSelected, setOrderSelected] = useState<IOrder>();

  const loadOrders = useCallback(async () => {
    const { data } = await api.get('/orders');
    setOrders(data);
  }, []);

  useEffect(() => {
    loadOrders();
  }, [setOrders, loadOrders]);

  const handleShowProducts = useCallback((order) => {
    setOrderSelected(order);
    setProducts(order.products);
  }, []);

  const handleMarkDelivered = useCallback(
    async (id) => {
      try {
        const response = await api.put('/orders-management', {
          id,
          user_id: user.id,
        });

        const updatedState = orders?.filter((item) => item.id !== id);

        if (updatedState) {
          response.data.status_message = 'delivered';

          const sorted = [...updatedState, response.data].sort((a, b) =>
            b.created_at > a.created_at ? 1 : -1,
          );

          setOrders(sorted);

          addToast({
            type: 'success',
            title: 'Delivered!',
            description: 'Order marked Delivered!',
          });
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Error processing your order, please try again later.',
          description: `Error processing your order trying to mark delivered. ${err.message}`,
        });
      }
    },
    [addToast, orders, user.id],
  );

  return (
    <Container>
      {orderSelected && (
        <ContentDetails>
          <TableContainer>
            {user.admin && (
              <ContactContainer>
                <BigField>{orderSelected.user.name}</BigField>
                <MediumField>
                  {orderSelected.user.address} -{' '}
                  {orderSelected.user.address_number} -{' '}
                  {orderSelected.user.address_complement}
                </MediumField>
                <div>
                  {orderSelected.user.address_city} -{' '}
                  {orderSelected.user.address_country}
                </div>
                <MediumField>
                  Phone: {orderSelected.user.mobile_phone}
                </MediumField>
                {orderSelected.user.delivery_instructions && (
                  <WarningField>
                    Delivery instructions:{' '}
                    {orderSelected.user.delivery_instructions}
                  </WarningField>
                )}
              </ContactContainer>
            )}

            <ProductsContainer>
              <h2>Products Ordered</h2>

              <table>
                <thead>
                  <tr>
                    <th>name</th>
                    <th>category</th>
                    <th>price/label</th>
                    <th>quantity</th>
                    <th className="align-right">total</th>
                  </tr>
                </thead>

                <tbody>
                  {products?.map((product) => (
                    <tr key={product.id}>
                      <td>{product.name}</td>
                      <td>{product.category.name}</td>
                      <td>
                        {product.price} {product.price_label}
                      </td>
                      <td>{product.quantity}</td>
                      <td className="total">
                        {formatValue(Number(product.price * product.quantity))}
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={4} />
                    <td>
                      {orderSelected?.total
                        ? formatValue(orderSelected.total)
                        : ''}
                    </td>
                  </tr>
                </tbody>
              </table>
            </ProductsContainer>
          </TableContainer>
        </ContentDetails>
      )}

      <Content>
        <TableContainer>
          <header>
            <h2>All Orders</h2>
          </header>
          <table>
            <thead>
              <tr>
                <th>id</th>
                <th>status</th>
                <th className="align-right">total</th>
                <th>Payment Info</th>
                <th>delivery date</th>
                <th>created at</th>
                <th>&nbsp;</th>
              </tr>
            </thead>

            <tbody>
              {orders?.map((order) => (
                <tr
                  key={order.id}
                  className={`${
                    orderSelected?.id === order.id ? 'active' : 'inactive'
                  } ${order.status_message
                    ?.replace(/\s+/g, '-')
                    .toLowerCase()}`}
                >
                  <td className="small">
                    {user.admin && `${order.user.name} - `}
                    {order.id.slice(-12)}
                  </td>
                  <td>{order?.status_message}</td>
                  <td>
                    {order.payments[0]?.status} by {order.payments[0]?.method}
                  </td>
                  <td className="total">{formatValue(Number(order.total))}</td>
                  <td>
                    {format(
                      parseISO(order.delivery_date.toString()),
                      'EEEE, dd MMM',
                    )}
                  </td>
                  <td className="small">
                    {format(
                      parseISO(order.created_at.toString()),
                      'EEEE, dd MMM yyyy @HH:mm',
                    )}
                  </td>
                  <td>
                    <SmallButton onClick={() => handleShowProducts(order)}>
                      Details
                    </SmallButton>
                    {user.admin && (
                      <SmallButton
                        onClick={() => handleMarkDelivered(order.id)}
                        disabled={order.delivered}
                      >
                        Delivered
                      </SmallButton>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableContainer>
      </Content>
    </Container>
  );
};

export default OrdersList;
